import axios from 'axios';
import Config from '../config';
import Cookies from 'js-cookie';

const Request = {
    get: async function (api: string) {
        try {
            let token = Cookies.get('token');
            let results = await axios.get(Config.hostname + api, {
                headers: {
                    accept: 'application/json',
                    authorization: "Bearer " + token
                }
            });
            return results;
        } catch (e: any) {
            throw e;
        }

    },
    post: async function (api: string, body: any) {

        try {
            let token = Cookies.get('token');
            let result = await axios.post(Config.hostname + api, body, {
                headers: {
                    accept: 'application/json',
                    authorization: "Bearer " + token,
                    'content-type': "application/json"
                }
            });
            return result;
        } catch (e: any) {
            throw e;
        }
    },
    patch: async function (api: string, body: any) {
        try {
            let token = Cookies.get('token');
            let result = await axios.patch(Config.hostname + api, body, {
                headers: {
                    accept: 'application/json',
                    authorization: "Bearer " + token,
                    'content-type': "application/json"
                }
            });
            return result;
        } catch (e: any) {
            throw e;
        }
    },
    put: async function (api: string, body: any) {
        try {
            let token = Cookies.get('token');
            let result = await axios.put(Config.hostname + api, body, {
                headers: {
                    accept: 'application/json',
                    authorization: "Bearer " + token,
                    'content-type': "application/json"
                }
            });
            return result;
        } catch (e: any) {
            throw e;
        }

    },
    delete: async function (api: string) {

        try {
            let token = Cookies.get('token');
            let result = await axios.delete(Config.hostname + api, {
                headers: {
                    accept: 'application/json',
                    authorization: "Bearer " + token,
                    'content-type': "application/json"
                }
            });
            return result;
        } catch (e: any) {
            throw e;
        }
    }
}

export default Request;