import React from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";

// import components
import ScheduleList, { IAllDataProps } from '../../components/schedule/scheduleList';

// import interfaces
import { IUserDataProps, IKEPDataProps, IScheduleDataProps, ISchedulePreviewProps, IIntakeDataProps, IEventDataProps, ISchedulePreviewLessonProps, IClassDataProps, ILessonDataProps } from './../../props/data';

// import services
import ScheduleService from './../../services/schedule';
import KEPService from './../../services/kep';
import ClassService from './../../services/class';
import ModuleService from './../../services/module';
import LessonService from './../../services/lesson';
import UserService from './../../services/user';
import IntakeService from './../../services/intake';
import GeneralService from './../../services/general';
import { Stack, Spinner, SpinnerSize, Dropdown, IDropdownOption, Text } from '@fluentui/react';
import EventService from '../../services/event';

// import fabric ui

// local interfaces
interface ISchedulePageProps { }
interface ISchedulePageState {
    loaded?: boolean;
    loadingSchedule: boolean;
    schedules?: IScheduleDataProps[];
    schedulesPreview?: ISchedulePreviewProps[];
    data: IAllDataProps;
    selected: {
        KEP?: string;
        intake?: string;
    }
    retrieveIntakesData?: boolean;
}

export default class SchedulePage extends React.Component<ISchedulePageProps, ISchedulePageState> {
    private userService: UserService = new UserService();
    private kepService: KEPService = new KEPService();
    private scheduleService: ScheduleService = new ScheduleService();
    private classService: ClassService = new ClassService();
    private moduleService: ModuleService = new ModuleService();
    private lessonService: LessonService = new LessonService();
    private intakeService: IntakeService = new IntakeService();
    private eventService: EventService = new EventService();

    constructor(props: ISchedulePageProps) {
        super(props);

        this.state = {
            loadingSchedule: false,
            selected: {},
            data: { KEP: [], classes: [], modules: [], lessons: [], events: [], teachers: [], intakes: [] }
        };
    }

    public async componentDidMount() {
        let teachers: IUserDataProps[] = await this.userService.getAll('Guru');
        let KEP: IKEPDataProps[] = await this.kepService.getAll();
        let classes = await this.classService.getAll();
        let modules = await this.moduleService.getAll();
        let lessons = await this.lessonService.getAll();
        let intakes = await this.intakeService.getAll();
        let events = await this.eventService.getAll();

        // get query string
        let selectedKEPId = GeneralService.getParameterByName("kep");
        let selectedIntakeId = GeneralService.getParameterByName("intake");

        this.setState({
            data: {
                KEP: JSON.parse(JSON.stringify(KEP)),
                classes: JSON.parse(JSON.stringify(classes)),
                modules: JSON.parse(JSON.stringify(modules)),
                lessons: JSON.parse(JSON.stringify(lessons)),
                events: JSON.parse(JSON.stringify(events)),
                teachers: JSON.parse(JSON.stringify(teachers)),
                intakes: JSON.parse(JSON.stringify(intakes))
            },
            loaded: true,
            selected: {
                KEP: selectedKEPId && KEP.findIndex((kep) => { return kep.id === selectedKEPId; }) > -1 ? selectedKEPId : undefined,
                intake: selectedIntakeId && intakes.findIndex((intake) => { return intake.id === selectedIntakeId; }) > -1 ? selectedIntakeId : undefined
            }
        }, () => {
            this.getSchedules();
        })
    }

    private getSchedules = async () => {
        const { selected } = this.state;
        if (selected.KEP && selected.intake) {
            this.setState({ loadingSchedule: true, schedules: undefined });
            let schedules: IScheduleDataProps[] = [];
            schedules = await this.scheduleService.getIntakeByKEPId(selected.KEP, selected.intake);

            let schedulesPreview = this.convertScheduleDataToSchedulePreview(schedules);

            this.setState({ schedules, schedulesPreview, loadingSchedule: false });
        }
    }

    private updateQueryString = () => {
        let { selected } = this.state;

        if (window.history.pushState) {
            var newurl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}${window.location.pathname}?${selected.KEP ? `kep=${selected.KEP}` : ""}${selected.KEP && selected.intake ? "&" : ""}${selected.intake ? `intake=${selected.intake}` : ""}`;
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    private groupBy(list: any, keyGetter: any) {
        const map = new Map();
        list.forEach((item: any) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    private convertScheduleDataToSchedulePreview(schedules: IScheduleDataProps[]) {
        schedules = schedules.filter((sch) => {
            let deleted = false;

            if (sch.KEP && sch.KEP.deleted) {
                deleted = true;
            } else if (sch.lesson && sch.lesson.deleted) {
                deleted = true;
            } else if (sch.lesson && sch.lesson.module && sch.lesson.module.deleted) {
                deleted = true;
            } else if (sch.lesson && sch.lesson.module && sch.lesson.module.class && sch.lesson.module.class.deleted) {
                deleted = true;
            }

            return !deleted;
        })
        let schedulesGroupedByDate: [string, IScheduleDataProps[]][] = Array.from(this.groupBy(schedules, (schedule: any) => schedule.date));
        let schedulePreview: ISchedulePreviewProps[] = schedulesGroupedByDate.sort((a, b) => (a[0] > b[0]) ? 1 : ((b[0] > a[0]) ? -1 : 0)).map((data) => {
            let event: IEventDataProps | undefined = undefined;
            let lessons: ISchedulePreviewLessonProps[] | undefined = undefined;
            let values = data[1];
            let date = data[0];

            values.forEach((value) => {
                event = value.event;
                if (value.lesson) {
                    if (!lessons) {lessons = [];}
                    lessons.push({
                        ...value.lesson,
                        scheduleId: value.id,
                        teacher: value.teacher,
                        rerandom: !value.teacher ? true : false,
                        hour: value.hour,
                        minute: value.minute,
                        status: value.status
                    });
                }
            });

            return {
                date,
                event,
                lessons,
                hour: '18',
                minute: '00'
            };
        });

        return schedulePreview;
    }

    private getFullUrl = () => {
        let { selected } = this.state;
        return `/pengacakan?${selected.KEP ? "kep=" + selected.KEP : ""}&${selected.intake ? "intake=" + selected.intake : ""}`;
    }

    private getIntakeData = (intakeId: string): IIntakeDataProps | undefined => {
        let { intakes } = this.state.data;
        return intakes.find((intake) => { return intake.id === intakeId; });
    }

    private getKEPData = (kepId: string): IKEPDataProps | undefined => {
        let { KEP } = this.state.data;
        return KEP.find((kep) => { return kep.id === kepId; });
    }

    public render() {
        let selectedKEPData: IKEPDataProps | undefined = this.state.selected.KEP ? this.getKEPData(this.state.selected.KEP) : undefined;
        let selectedIntakeData: IIntakeDataProps | undefined = this.state.selected.intake ? this.getIntakeData(this.state.selected.intake) : undefined;

        return (
            <Stack styles={{ root: { padding: 20 } }} tokens={{ childrenGap: 15 }}>
                <Stack.Item>
                    <h2>Jadwal KEP</h2>
                </Stack.Item>
                {!this.state.loaded ? <Spinner label={"Mempersiapkan data ..."} labelPosition={"bottom"} size={SpinnerSize.large} /> : null}
                {
                    this.state.loaded ? (
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item styles={{root: {width: '50%'}}}>
                                <Dropdown
                                    placeholder="Pilih tahun pelajaran ..."
                                    label="Tahun pelajaran"
                                    disabled={this.state.loadingSchedule}
                                    selectedKey={this.state.selected.intake}
                                    onChange={async (e?, selectedOption?: IDropdownOption) => {
                                        let { selected } = this.state;
                                        selected.intake = selectedOption ? selectedOption.key as string : undefined;
                                        this.setState({ selected });
                                        if (selected.intake) {
                                            // load kep data
                                            this.setState({retrieveIntakesData: true, selected});
                                            const intakeService = new IntakeService();
                                            const schedules = await intakeService.schedules(selected.intake);
                                            let data = this.state.data;
                                            data.KEP = data.KEP.map((kep) => {
                                                const hasSchedule = schedules.findIndex((sch: any) => sch.KEPId === kep.id) > -1;
                                                return {...kep, scheduleGenerated: hasSchedule};
                                            });
                                            this.setState({
                                                data: {...data},
                                                retrieveIntakesData: false
                                            });
                                        }
                                        
                                        this.updateQueryString(); 
                                        this.getSchedules();
                                    }}
                                    options={this.state.data.intakes.map((intake) => { return { key: intake.id, text: intake.name } })} />
                            </Stack.Item>
                            <Stack.Item styles={{root: {width: '50%'}}}>
                                <Dropdown
                                    placeholder="Pilih KEP ..."
                                    label="KEP"
                                    disabled={this.state.loadingSchedule || this.state.retrieveIntakesData}
                                    selectedKey={this.state.selected.KEP}
                                    onChange={(e?, selectedOption?: IDropdownOption) => {
                                        let { selected } = this.state;
                                        selected.KEP = selectedOption ? selectedOption.key as string : undefined;

                                        this.setState({ selected }, () => { this.updateQueryString(); this.getSchedules() });
                                    }}
                                    options={this.state.data.KEP.filter((kep) => kep.scheduleGenerated).map((kep) => { return { key: kep.id, text: kep.name } })} />
                                {this.state.retrieveIntakesData ? <Spinner label='Memuat daftar KEP ...' labelPosition='right' size={SpinnerSize.small} /> : null}
                            </Stack.Item>
                        </Stack>
                    ) : null
                }
                {this.state.loadingSchedule ? <Spinner label={"Mendapatkan semua jadwal ..."} labelPosition={"bottom"} size={SpinnerSize.large} /> : null}
                {
                    this.state.schedules && this.state.schedules.length > 0 && selectedKEPData && selectedIntakeData ? (
                        <Stack.Item>
                            <ScheduleList allowDelete={true} data={this.state.data} schedules={this.state.schedulesPreview ? this.state.schedulesPreview : []} selected={{
                                KEP: selectedKEPData,
                                intake: selectedIntakeData,
                                startDate: this.state.schedules[0].date,
                                hour: '18',
                                minute: '00'
                            }} />
                        </Stack.Item>
                    ) : null
                }
                {
                    this.state.schedules && this.state.schedules.length < 1 ? (
                        <Text>Jadwal tidak dapat ditemukan. Klik <Link to={this.getFullUrl()}>link berikut</Link> untuk membuat jadwal baru.</Text>
                    ) : null
                }
            </Stack>
        );
    }
}
