import React, { RefObject } from 'react';
import moment from 'moment';

// import interfaces
import { IClassDataProps, IKEPDataProps, ILessonDataProps, IModuleDataProps, IScheduleDataProps, IUserDataProps } from '../../props/data';

// import services
import ScheduleService from './../../services/schedule';
import KEPService from './../../services/kep';
import LessonService from './../../services/lesson';
import { Text, IColumn, Stack, Spinner, SpinnerSize, MessageBar, MessageBarType, DetailsList, SelectionMode, CommandBar, CommandBarButton } from '@fluentui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';

// import fabric ui

// local interfaces
interface ITeacherScheduleProps {
    data: IScheduleDataProps;
    date: string;
    lesson: ILessonDataProps;
    module?: IModuleDataProps;
    class?: IClassDataProps;
    KEP: IKEPDataProps;
}
interface IAllDataProps {
    lessons: ILessonDataProps[];
    KEP: IKEPDataProps[];
}
interface ITeacherScheduleListProps {
    data?: IAllDataProps;
    teacher: IUserDataProps;
    startDate: string;
    endDate: string;
}
interface ITeacherScheduleListState {
    loaded?: boolean;
    data?: IAllDataProps;
    schedules?: ITeacherScheduleProps[];
    teacher: IUserDataProps;
    startDate: string;
    endDate: string;
}

export default class TeacherScheduleList extends React.Component<ITeacherScheduleListProps, ITeacherScheduleListState> {
    private scheduleService: ScheduleService = new ScheduleService();
    private kepService: KEPService = new KEPService();
    private lessonService: LessonService = new LessonService();
    private tableRef: RefObject<HTMLTableElement> = React.createRef<HTMLTableElement>()
    private columns: IColumn[] = [
        {
            key: "date",
            fieldName: "date",
            name: "Tanggal",
            minWidth: 0,
            maxWidth: 200,
            onRender: (item: ITeacherScheduleProps) => {
                return <Text>{moment(item.date).format("DD/MM/YYYY")}</Text>;
            }
        },
        {
            key: "lesson",
            fieldName: "lesson",
            name: "Pelajaran",
            minWidth: 300,
            onRender: (item: ITeacherScheduleProps) => {
                return <Text>{item.lesson.name}</Text>;
            }
        },
        {
            key: "module",
            name: "Modul",
            minWidth: 200,
            onRender: (item: ITeacherScheduleProps) => {
                return <Text>{item.module ? item.module.name : ""}</Text>;
            }
        },
        {
            key: "class",
            name: "Kelas",
            minWidth: 200,
            onRender: (item: ITeacherScheduleProps) => {
                return <Text>{item.class ? item.class.name : ""}</Text>;
            }
        },
        {
            key: "kep",
            fieldName: "kep",
            name: "KEP",
            minWidth: 300,
            onRender: (item: ITeacherScheduleProps) => {
                return <Text>{item.KEP.name}</Text>;
            }
        }
    ];

    constructor(props: ITeacherScheduleListProps) {
        super(props);
        this.state = {
            data: this.props.data,
            teacher: this.props.teacher,
            startDate: this.props.startDate,
            endDate: this.props.endDate
        }
    }

    private init = async (props: ITeacherScheduleListProps) => {
        let data = props.data || this.state.data;
        let KEP = data ? data.KEP : await this.kepService.getAll();
        let lessons = data ? data.lessons : await this.lessonService.getAll();

        this.setState({
            data: { KEP, lessons },
            teacher: props.teacher,
            startDate: props.startDate,
            endDate: props.endDate
        }, () => {
            this.getTeacherSchedule();
        });
    }

    public componentDidMount() {
        this.init(this.props);
    }

    public componentWillReceiveProps(props: ITeacherScheduleListProps) {
        this.init(props);
    }

    private async getTeacherSchedule() {
        this.setState({ loaded: false });
        let data = await this.scheduleService.getRangeByTeacherId(this.state.teacher.id, this.state.startDate, this.state.endDate);
        let schedules: any = [];

        data.forEach((d) => {
            const selectedLesson = d.lessonId && this.state.data ? this.state.data.lessons.find((lesson) => { return lesson.id === d.lessonId; }) : undefined;
            const KEP = this.state.data ? this.state.data.KEP.find((kep) => { return kep.id === d.KEPId; }) : undefined;

            const exist = schedules.findIndex((sch: any) => {
                return sch.data.KEPId === d.KEPId &&
                    sch.data.eventId === d.eventId &&
                    sch.data.lessonId === d.lessonId &&
                    sch.data.teacherId === d.teacherId &&
                    sch.data.date === d.date
            }) > -1;

            if (!exist && KEP && selectedLesson) {
                schedules.push({
                    data: d,
                    date: d.date,
                    lesson: selectedLesson,
                    module: selectedLesson ? selectedLesson.module : undefined,
                    class: selectedLesson && selectedLesson.module ? selectedLesson.module.class : undefined,
                    KEP: d.KEPId && this.state.data ? this.state.data.KEP.find((kep) => { return kep.id === d.KEPId; }) : undefined
                })
            }
        })

        schedules = schedules.filter((schedule: any) => {
            return schedule.KEP && schedule.lesson;
        });


        this.setState({
            schedules: (schedules as ITeacherScheduleProps[]).sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)),
            loaded: true
        });
    }

    public render() {
        return (
            <Stack>
                {
                    !this.state.loaded ? (
                        <Stack horizontalAlign="baseline"><Spinner label="Memuat jadwal guru ..." labelPosition="right" size={SpinnerSize.large} /></Stack>
                    ) : null
                }
                {
                    this.state.loaded && this.state.schedules && this.state.schedules.length === 0 ? (
                        <Stack.Item>
                            <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                                <Stack verticalAlign={"center"} styles={{ root: { height: '100%' } }}>
                                    <Text>Guru {this.state.teacher.name} tidak memiliki jadwal antara tanggal {moment(this.state.startDate).format("DD MMMM YYYY")} sampai {moment(this.state.endDate).format("DD MMMM YYYY")}</Text>
                                </Stack>
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                {
                    this.state.loaded && this.state.schedules && this.state.schedules.length > 0 ? <>
                        <CommandBar items={[
                            {
                                key: "exportToExcel",
                                text: "Download (excel)",
                                iconProps: { iconName: "Download" },
                                onRender: () => {
                                    return (
                                        <>
                                            <DownloadTableExcel
                                                filename={"Jadwal mengajar " + this.props.teacher.name + " " + moment(this.props.startDate).format("DDMM") + "-" + moment(this.props.endDate).format("DDMM")}
                                                sheet="users"
                                                currentTableRef={this.tableRef.current}
                                            >
                                                <CommandBarButton iconProps={{ iconName: "Download" }} text={"Download (Excel)"} styles={{ root: { height: '100%' } }} />
                                            </DownloadTableExcel>
                                            <table style={{ display: 'none' }} ref={this.tableRef}>
                                                <tbody>
                                                    <tr>
                                                        <th>Tanggal</th>
                                                        <th>Pelajaran</th>
                                                        <th>Modul</th>
                                                        <th>Kelas</th>
                                                        <th>KEP</th>
                                                    </tr>
                                                    {(this.state.schedules || []).map(d => (
                                                        <tr>
                                                            <td>{moment(d.date).format("DD/MM/YYYY")}</td>
                                                            <td>{d.lesson.name}</td>
                                                            <td>{d.module?.name || ""}</td>
                                                            <td>{d.class?.name || ""}</td>
                                                            <td>{d.KEP.name}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </>
                                    );
                                }
                            },
                        ]} />
                        <DetailsList
                            columns={this.columns}
                            items={this.state.schedules}
                            selectionMode={SelectionMode.none} />
                    </> : null
                }
            </Stack>
        );
    }
}
